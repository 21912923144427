<template>
  <div>
    <!--    Title    -->
    <h6>
      {{ title }}

      <!--    Info    -->
      <b-button
        v-if="tip && tip.length > 0"
        v-b-tooltip.focus.v-primary
        :title="tip"
        variant="flat-primary"
        class="infoBadge btn-icon px-0 py-0"
      >
        <feather-icon
          icon="InfoIcon"
        />
      </b-button>
    </h6>

    <!--    Body    -->
    <div
      class="mt-50 mx-1 d-flex flex-wrap row align-items-center justify-content-start"
      :class="myClass"
    >
      <slot />
    </div>

    <!--    Popover    -->
    <!--    <b-popover-->
    <!--      v-if="tip && tip.length > 0"-->
    <!--      :target="'popover' + newId"-->
    <!--      triggers="focus"-->
    <!--      variant="primary"-->
    <!--    >-->
    <!--      <span>{{ tip }}</span>-->
    <!--    </b-popover>-->
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    title: {
      required: true,
    },
    tip: {
      required: false,
    },
    myClass: {
      required: false,
    },
  },
  data: () => ({
    newId: `_${Math.random().toString(36).substr(2, 9)}`,
  }),
}
</script>

<style lang="scss">
/*    Drag Drop & Color Picker    */
.dragDrop, .colorPicker {
  position: relative;

  /*    First div    */
  div,
  img {
    position: relative;

    width : 240px;
    height: 120px;

    display: flex;
    justify-content: center;
  }

  /*    If img is rectangle    */
  &.rec div,
  &.rec img {
    width: 120px;
    height: 120px;
  }

  /*    Sets size    */
  div, button, input {
    width: 100%;
    height: 100%;

    max-width: 240px;
    max-height: 120px;
  }

  /*    Sets size for img    */
  img { max-height: 150px }

  /*    Add button    */
  button {
    position: absolute;

    opacity: 0;

    transition: opacity .14s ease-in-out;

    /*    Input    */
    input {
      position: absolute;

      left: 0;
      top : 0;

      cursor: pointer;

      opacity: 0;
    }

    /*    Button Hover    */
    &:hover { opacity: .5; }
  }
}

/*    Color Picker    */
.colorPicker {
  /*    Sets size    */
  div, button, input {
    max-width: unset;
    max-height: unset;

    height: 8.5rem;

    cursor: pointer;

    opacity: 1;

    transition: opacity .14s ease-in-out;

    overflow: hidden;

    outline: none;
    padding: unset;
    border:none;
    background-image:none;
    background-color:transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  input:hover {
    opacity: .75;
  }
}

/*    Info Badge    */
.infoBadge {
  position: absolute;

  width: 1.25rem;
  height: 1.25rem;

  margin-left: .34rem;

  svg {
    width: 100%;
    height: 100%;
  }
}

/*    Offer Website Add    */
.offerWebsiteAdd {
  bottom: 0;
  right: -3.5rem;
}
</style>
