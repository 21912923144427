var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mx-0"},[_c('b-col',{staticClass:"px-1",attrs:{"sm":"12","md":"6","lg":"8"}},[_c('my-option',{attrs:{"title":_vm.$t('Title'),"tip":_vm.$t('MaximumLength') + ': 50'}},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":_vm.$t('Title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"id":"popover-QAndATitle","placeholder":_vm.$t('Title'),"state":errors.length > 0 ? false:null,"maxlength":"50"},model:{value:(_vm.newWebsite.QAndATitle),callback:function ($$v) {_vm.$set(_vm.newWebsite, "QAndATitle", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"newWebsite.QAndATitle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('b-popover',{attrs:{"target":"popover-QAndATitle","triggers":"focus","variant":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t('MaximumLength') + ': 50'))])])],1)]}}])})],1)],1)],1),_c('b-col',{staticClass:"px-1",attrs:{"sm":"12","md":"12","lg":"12"}},[_c('my-option',{attrs:{"title":_vm.$t('task.Description'),"tip":_vm.$t('MaximumLength') + ': 110'}},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":_vm.$t('Title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-textarea',{attrs:{"placeholder":_vm.$t('Title'),"state":errors.length > 0 ? false:null,"maxlength":"110"},model:{value:(_vm.newWebsite.QAndADesc),callback:function ($$v) {_vm.$set(_vm.newWebsite, "QAndADesc", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"newWebsite.QAndADesc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('b-popover',{attrs:{"target":"popover-bannerDesc","triggers":"focus","variant":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t('MaximumLength') + ': 110'))])])],1)]}}])})],1)],1),_c('b-row',{staticClass:"mx-0"},[_c('b-col',{staticClass:"px-1",attrs:{"sm":"12","md":"12","lg":"8"}},[_c('my-option',{attrs:{"title":_vm.$t('offer.website.QAndA')}},[_c('b-row',{staticClass:"w-100 mx-0 px-0"},[_c('b-col',{staticClass:"d-flex mb-25 px-0",attrs:{"sm":"12","md":"12","lg":"12"}},[_c('b-form-input',{attrs:{"id":"popover-question","placeholder":_vm.$t('Question'),"maxlength":"120"},model:{value:(_vm.newQAndA.question),callback:function ($$v) {_vm.$set(_vm.newQAndA, "question", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"newQAndA.question"}}),_c('b-popover',{attrs:{"target":"popover-question","triggers":"focus","variant":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t('MaximumLength') + ': 120'))])]),_c('b-button',{staticClass:"btn-icon ml-1 offerWebsiteAdd",attrs:{"variant":"primary"},on:{"click":function () {
                  if (_vm.newQAndA.question.length > 0 && _vm.newQAndA.answer.length > 0) {
                    _vm.newWebsite.QAndAOptions.push({
                      question: JSON.parse(JSON.stringify(_vm.newQAndA.question)),
                      answer: JSON.parse(JSON.stringify(_vm.newQAndA.answer)),
                    })

                    _vm.newQAndA.question = ''
                    _vm.newQAndA.answer = ''
                  }
                }}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1),_c('b-col',{staticClass:"d-flex mt-25 px-0",attrs:{"sm":"12","md":"12","lg":"12"}},[_c('b-form-textarea',{attrs:{"id":"popover-answer","placeholder":_vm.$t('Answer'),"maxlength":"300"},model:{value:(_vm.newQAndA.answer),callback:function ($$v) {_vm.$set(_vm.newQAndA, "answer", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"newQAndA.answer"}}),_c('b-popover',{attrs:{"target":"popover-answer","triggers":"focus","variant":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t('MaximumLength') + ': 300'))])])],1)],1),_vm._l((_vm.newWebsite.QAndAOptions),function(item,index){return _c('div',{key:index,staticClass:"w-100 px-0 mx-0"},[_c('b-row',{staticClass:"w-100 px-0 mx-0",on:{"click":function($event){_vm.selectedOption = { id: index, opt: JSON.parse(JSON.stringify(item)) }
              _vm.showChangeQAndAOptionsModal = true}}},[_c('b-col',{staticClass:"d-flex mb-25 mt-1 px-0",attrs:{"sm":"12","md":"12","lg":"12"}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Question'),"readonly":""},model:{value:(item.question),callback:function ($$v) {_vm.$set(item, "question", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.question"}})],1),_c('b-col',{staticClass:"mt-25 d-flex align-items-end px-0",attrs:{"sm":"12","md":"12","lg":"12"}},[_c('b-form-textarea',{attrs:{"placeholder":_vm.$t('Answer'),"readonly":""},model:{value:(item.answer),callback:function ($$v) {_vm.$set(item, "answer", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.answer"}}),_c('b-button',{staticClass:"btn-icon ml-1",attrs:{"variant":"outline-danger offerWebsiteAdd"},on:{"click":function($event){_vm.newWebsite.QAndAOptions.splice(index, 1)

                  _vm.newWebsite.QAndAOptions.push()}}},[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)],1)],1),_c('hr',{staticClass:"mb-0"})],1)})],2)],1)],1),_c('b-modal',{attrs:{"title":_vm.$t('offer.website.QAndA'),"size":"sm","hide-footer":"","no-close-on-backdrop":""},model:{value:(_vm.showChangeQAndAOptionsModal),callback:function ($$v) {_vm.showChangeQAndAOptionsModal=$$v},expression:"showChangeQAndAOptionsModal"}},[_c('b-form',[_c('validation-provider',{staticClass:"w-100",attrs:{"name":_vm.$t('Question'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Question')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Question'),"state":errors.length > 0 ? false:null,"maxlength":"120"},model:{value:(_vm.selectedOption.opt.question),callback:function ($$v) {_vm.$set(_vm.selectedOption.opt, "question", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"selectedOption.opt.question"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{staticClass:"w-100",attrs:{"name":_vm.$t('Answer'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Answer')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Answer'),"state":errors.length > 0 ? false:null,"maxlength":"300"},model:{value:(_vm.selectedOption.opt.answer),callback:function ($$v) {_vm.$set(_vm.selectedOption.opt, "answer", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"selectedOption.opt.answer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('b-button',{staticClass:"ml-25",attrs:{"variant":"success","size":"sm"},on:{"click":function($event){_vm.newWebsite.QAndAOptions[_vm.selectedOption.id] = _vm.selectedOption.opt
          _vm.$forceUpdate()

          _vm.showChangeQAndAOptionsModal = false}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }