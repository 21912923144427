<template>
  <div>
    <!--    START::Name    -->
    <b-form-group :label="`${$t('SectionName')}*`">
      <validation-provider
        v-slot="{ errors }"
        :name="$t('SectionName')"
        rules="required"
      >
        <b-form-input
          v-model="newWebsite.cooperationName"
          :label="$t('SectionName')"
          :placeholder="$t('SectionName')"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <!--    END::Name    -->

    <!--    START::Background    -->
    <sw-form-input-color
      v-model="newWebsite.cooperationBackgroundColor"
      :default-color="{ hex: '#EBEBEB', rgb: 'rgb(235, 235, 235)' }"
      :label="$t('Styles.Background')"
      switch-enabled
      :enabled="newWebsite.cooperationBackgroundVisible"
      width="140px"
      height="140px"
      class="mb-1"
      @change-enabled="newWebsite.cooperationBackgroundVisible = $event; $forceUpdate()"
    />
    <!--    END::Background    -->

    <div class="mb-50">
      {{ $t('offer.website.Logos') }}
      <sw-icon
        v-b-tooltip.hover.v-primary
        :title="`${$tc('file.Max', 1, { size: 100 })}. ${$t('LogosAspectRatio')}`"
        icon="InfoIcon"
        class="mt-n25"
      />
      :
    </div>

    <!--    START::Logos    -->
    <b-row style="row-gap: 1.4rem">
      <b-col
        v-for="(logo, i) in newWebsite.cooperationFiles"
        :key="i"
        md="4"
        lg="3"
        xl="2"
        class="d-flex flex-column align-items-center"
        style="row-gap: 1rem"
      >
        <!--    START::Product Picture    -->
        <sw-form-input-image
          v-model="logo.file"
          :clearable="false"
          fluid-x
          max-size="100"
        />
        <!--    END::Product Picture    -->

        <!--    START::Delete Button    -->
        <b-button
          variant="danger"
          size="sm"
          class="position-absolute btn-icon p-25"
          style="top: -.34rem; right: 1rem; z-index: 2;"
          @click="newWebsite.cooperationFiles.splice(i, 1); $forceUpdate()"
        >
          <sw-icon icon="TrashIcon" />
        </b-button>
        <!--    END::Delete Button    -->
      </b-col>

      <!--    START::Add Button    -->
      <b-col
        md="4"
        lg="3"
        xl="2"
        class="cursor-pointer"
        style="min-height: 118px"
        @click="newWebsite.cooperationFiles.push({ file: '' }); $forceUpdate()"
      >
        <b-alert
          variant="primary"
          show
          class="d-flex align-items-center justify-content-center h-100 w-100"
          style="gap: .14rem"
        >
          <sw-icon
            icon="PlusIcon"
            size="34"
          />
        </b-alert>
      </b-col>
      <!--    END::Add Button    -->
    </b-row>
    <!--    END::Logos    -->
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { VBTooltip } from 'bootstrap-vue'

export default {
  components: { ValidationProvider },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    newWebsite: { },
  }),
  mounted() {
    this.newWebsite = this.value
    this.newWebsite.cooperationFiles = this.newWebsite.cooperationFiles.map(file => (file?.file ? file : ({ file })))
  },
}
</script>
