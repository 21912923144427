<template>
  <div>
    <!--    START::Name    -->
    <b-form-group :label="`${$t('SectionName')}*`">
      <validation-provider
        v-slot="{ errors }"
        :name="$t('SectionName')"
        rules="required"
      >
        <b-form-input
          v-model="newWebsite.portfolioName"
          :label="$t('SectionName')"
          :placeholder="$t('SectionName')"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <!--    END::Name    -->

    <div class="mb-50">
      {{ $t('Products') }}
      <sw-icon
        v-b-tooltip.hover.v-primary
        :title="`${$tc('file.Max', 1, { size: 100 })}. ${$t('AvatarsAspectRatio')}`"
        icon="InfoIcon"
        class="mt-n25"
      />
      :
    </div>

    <!--    START::Products    -->
    <b-row style="row-gap: 1.4rem">
      <b-col
        v-for="(product, i) in newWebsite.offerWebTemplatePortfolioItems"
        :key="i"
        md="4"
        lg="3"
        xl="2"
        class="d-flex flex-column align-items-center"
        style="row-gap: 1rem"
      >
        <!--    START::Product Picture    -->
        <sw-form-input-image
          v-model="product.file"
          :clearable="false"
          width="140px"
          height="140px"
          max-size="100"
        />
        <!--    END::Product Picture    -->

        <!--    START::Product Content    -->
        <div
          class="d-flex flex-column align-items-center w-100"
          style="row-gap: .5rem"
        >
          <!--    START::Product Content    -->
          <b-form-group class="mb-0 w-100">
            <validation-provider
              v-slot="{ errors }"
              :name="$t('Content')"
            >
              <b-form-input
                v-model="product.translations[0].title"
                :label="$t('Content')"
                :placeholder="$t('Content')"
                maxlength="34"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!--    END::Product Content    -->

          <!--    START::Product Link    -->
          <b-form-group class="mb-0 w-100">
            <validation-provider
              v-slot="{ errors }"
              :name="$t('Link')"
            >
              <b-form-input
                v-model="product.translations[0].url"
                :label="$t('Link')"
                :placeholder="$t('Link')"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!--    END::Product Link    -->
        </div>
        <!--    END::Product Content    -->

        <!--    START::Delete Button    -->
        <b-button
          variant="danger"
          size="sm"
          class="position-absolute btn-icon p-25"
          style="top: -.34rem; right: 1rem; z-index: 2;"
          @click="newWebsite.offerWebTemplatePortfolioItems.splice(i, 1); $forceUpdate()"
        >
          <sw-icon icon="TrashIcon" />
        </b-button>
        <!--    END::Delete Button    -->
      </b-col>

      <!--    START::Add Button    -->
      <b-col
        md="4"
        lg="3"
        xl="2"
        class="cursor-pointer"
        style="min-height: 239px"
        @click="newWebsite.offerWebTemplatePortfolioItems.push(JSON.parse(JSON.stringify(emptyProduct))); $forceUpdate()"
      >
        <b-alert
          variant="primary"
          show
          class="d-flex align-items-center justify-content-center h-100 w-100"
          style="gap: .14rem"
        >
          <sw-icon
            icon="PlusIcon"
            size="34"
          />
        </b-alert>
      </b-col>
      <!--    END::Add Button    -->
    </b-row>
    <!--    END::Products    -->
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { VBTooltip } from 'bootstrap-vue'

export default {
  components: { ValidationProvider },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    newWebsite: { },

    emptyProduct: {
      file: null,
      translations: [
        {
          locale: 'pl_pl',
          title: '',
          url: '',
        },
      ],
    },
  }),
  watch: {
    value: {
      deep: true,
      handler(n) {
        this.newWebsite = n
      },
    },
  },
  mounted() {
    this.newWebsite = this.value
  },
  methods: {
    onChangeImage(event, option, maxSize) {
      if (event.target.files[0].size > maxSize * 1000) {
        this.showToast('danger', this.$i18n.tc('MoreThanKB', 1, {
          type: this.$i18n.t(`SystemMoreThan.${option}`),
          size: maxSize,
        }))
        return
      }

      const [first] = event.target.files

      if (typeof this.newWebsite[option] !== 'undefined') {
        this.newWebsite[option] = first
      } else {
        // eslint-disable-next-line default-case
        switch (option) {
          case 'newOption-avatar':
            this.newOption.avatar = first
            break
        }
      }

      document.querySelector(`#img-${option}`).src = URL.createObjectURL(first)

      // eslint-disable-next-line consistent-return
      return first
    },
  },
}
</script>
