<!--eslint-disable-->

<template>
  <b-row
    class="w-100 mx-0 px-0 cursor-pointer"
    @click="$emit('edit')"
  >
    <!--    Picture    -->
    <b-col
      sm="12"
      md="12"
      lg="12"
      class="d-flex mt-1 mx-0 px-0"
    >
      <div
        class="mx-25 mb-25 dragDrop rec d-flex row align-item-center justify-content-start"
      >
        <div>
          <b-img
            :id="'img-newOption-avatar-' + id"
            class="px-0 py-0 center cursor-pointer"
            thumbnail
            fluid
            alt="Transparent image"
            :src="require('@/assets/icons/user/user.svg')"
          />
        </div>
      </div>
    </b-col>

    <!--    Title    -->
    <b-col
      sm="12"
      md="12"
      lg="12"
      class="d-flex mt-25 mx-0 px-0"
    >
      <b-form-input
        v-model.trim="item.translations[translationNr].title"
        :placeholder="$t('Title')"
        readonly
        class="cursor-pointer"
      />
    </b-col>

    <!--    Desc    -->
    <b-col
      sm="12"
      md="12"
      lg="12"
      class="mt-25 d-flex mx-0 px-0"
    >
      <b-form-textarea
        v-model.trim="item.translations[translationNr].description"
        :placeholder="$t('Opinion')"
        readonly
        class="cursor-pointer"
      />
    </b-col>

    <!--    Creator    -->
    <b-col
      sm="12"
      md="12"
      lg="12"
      class="mt-25 d-flex mx-0 px-0"
    >
      <b-form-input
        v-model.trim="item.translations[translationNr].author"
        :placeholder="$t('Creator')"
        readonly
        class="cursor-pointer"
      />
      <b-button
        variant="outline-danger"
        class="btn-icon ml-1 offerWebsiteAdd"
        @click="$emit('delete') "
      >
        <feather-icon icon="MinusIcon" />
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { BFormTextarea, BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BFormTextarea,
  },
  props: ['item', 'defImg', 'translationNr'],
  emits: ['delete', 'edit'],
  data: () => ({
    id: `${Math.random().toString(36).substr(2, 9)}`,
  }),
  mounted() { this.setImg() },
  updated() { this.setImg() },
  methods: {
    setImg() {
      if (this.item.avatar && !this.item.avatar.token) {
        document.querySelector(`#img-newOption-avatar-${this.id}`).src = typeof this.item.avatar === 'object' ? URL.createObjectURL(this.item.avatar) : this.defImg
      } else if (this.item.avatar && this.item.avatar.token) {
        document.querySelector(`#img-newOption-avatar-${this.id}`).src = `data:image/webp;base64,${this.item.avatar.preview}`
      } else {
        document.querySelector(`#img-newOption-avatar-${this.id}`).src = this.defImg
      }
    },
  },
}
</script>
